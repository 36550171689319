import './Espacio.css';

const Espacio = () => {
    return (
        <div id='espacio' className="espacio-bkg container-fluid">
            <div className="row text-center bkg-light">
                <div className="col-12 mb-3">
                    <p className="title">Súmate como espacio de trabajo en nomad</p>
                    <p className='subtitle'>Únete a nomad, publica tu espacio y llega a más de 250.000 usuarios en todo el mundo.</p>
                </div>
                <div className="col-12">
                    <a
                        href="mailto:espacios@nomad.com.ar" target="_blank" rel="noreferrer"
                        className="btn btn-primary btn-lg"
                    >
                        Contáctanos
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Espacio;
