import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./Header.css";

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false); 

  const handleScroll = (sectionId) => {
    if (location.pathname !== "/") {
      navigate("/");
    }
    setTimeout(() => {
      const section = document.querySelector(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }, 100); 
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-white py-3">
      <div className="container">
        <Link to="/" className="navbar-brand">
          <img className="vector-icon" alt="nomad Logo" src="./images/logo-nomad.svg" />
        </Link>

        <button
          className="navbar-toggler"
          type="button"
          onClick={() => setMenuOpen(!menuOpen)} 
          aria-controls="navbarNav"
          aria-expanded={menuOpen}
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className={`collapse navbar-collapse ${menuOpen ? "show" : ""}`} id="navbarNav">
          <ul className="navbar-nav ms-auto align-items-center">
            <li className="nav-item">
              <button
                className="nav-link btn btn-link"
                onClick={() => {
                  handleScroll("#inicio");
                  setMenuOpen(false); 
                }}
              >
                Inicio
              </button>
            </li>
            <li className="nav-item">
              <button
                className="nav-link btn btn-link"
                onClick={() => {
                  handleScroll("#servicios");
                  setMenuOpen(false);
                }}
              >
                Servicios
              </button>
            </li>
            <li className="nav-item">
              <button
                className="nav-link btn btn-link"
                onClick={() => {
                  handleScroll("#funcion");
                  setMenuOpen(false);
                }}
              >
                Cómo funciona
              </button>
            </li>
            <li className="nav-item">
              <button
                className="nav-link btn btn-link"
                onClick={() => {
                  handleScroll("#descarga");
                  setMenuOpen(false);
                }}
              >
                Descarga la app
              </button>
            </li>
            <li className="nav-item">
              <button
                className="nav-link btn btn-link"
                onClick={() => {
                  handleScroll("#espacio");
                  setMenuOpen(false);
                }}
              >
                Suma tu espacio
              </button>
            </li>
            <li className="nav-item">
              <Link
                to="/register"
                className="btn btn-outline-dark btn-sm"
                onClick={() => setMenuOpen(false)}
              >
                Crea tu cuenta
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
