import { useState, useEffect } from 'react';
import './Banner.css';

const Banner = () => {
  const [isAppInstalled, setIsAppInstalled] = useState(false);

  useEffect(() => {
    const checkIfAppInstalled = () => {
      if (window.matchMedia('(display-mode: standalone)').matches) {
        setIsAppInstalled(true);
      }
    };

    window.addEventListener('load', checkIfAppInstalled);

    return () => {
      window.removeEventListener('load', checkIfAppInstalled);
    };
  }, []);

  const handleDownloadAPK = () => {
    try {
      const isAndroid = /Android/i.test(navigator.userAgent);
      if (isAndroid) {
        const link = document.createElement('a');
        link.href = 'https://nomad.com.ar/pwa/nomad.apk';
        link.download = 'nomad.apk';
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        alert('La descarga de la APK comenzará en breve.');
      } else {
        alert('La aplicación solo está disponible para dispositivos Android.');
      }
    } catch (error) {
      console.error('Error al descargar la APK:', error);
      alert('No se pudo iniciar la descarga. Intenta más tarde.');
    }
  };

  return (
    <div className="container banner">
      <div className="row align-items-center">
        <div className="col-12 col-md-6 mb-4 mb-md-0">
          <div>
            <h1 className="title">Encuentra tu espacio de trabajo ideal en la ciudad</h1>
            <h2 className="subtitle">Trabaja donde quieras, cuando quieras</h2>
            <p className="subtitle mt-4">Con <span className='green'>nomad</span>, accede a una <span className='green'>red centralizada de coworking, cafés, y espacios de trabajo</span> diseñados para freelancers, nómadas digitales, startups, y profesionales que buscan flexibilidad y productividad.</p>
            <button
              className="btn btn-primary mt-4"
              onClick={handleDownloadAPK}
              aria-label="Descargar Nomad APK"
            >
              {isAppInstalled ? 'Actualiza nomad aquí' : 'Descarga nomad aquí'}
            </button>
          </div>
        </div>
        <div className="col-12 col-md-6 text-center">
          <img className="img-fluid" alt="Banner" src="./images/banner.jpg" />
        </div>
      </div>
    </div>
  );
};

export default Banner;
