import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/estilos.css?v=1.0";
import Header from "./components/Header";
import Banner from "./components/Banner";
import Caracteristicas from "./components/Caracteristicas";
import Servicios from "./components/Servicios";
import Funcion from "./components/Funcion";
import Descarga from "./components/Descarga";
import Espacio from "./components/Espacio";
import Footer from "./components/Footer";
import Register from "./components/Register";

function Landing() {
  return (
    <div>
      <Header />
      <Banner />
      <Caracteristicas />
      <Servicios />
      <Funcion />
      <Descarga />
      <Espacio />
      <Footer />
    </div>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Landing />} />
        
        <Route path="/register" element={<Register />} />
      </Routes>
    </Router>
  );
}

export default App;
